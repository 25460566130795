<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="my-0">
          Manual Order
        </h4>
      </b-card-header>
      <b-card-body>
        <b-alert
          variant="danger"
          :show="errorMessage ? true : false"
        >
          <div class="alert-body">
            <p>
              {{ errorMessage }}
            </p>
          </div>
        </b-alert>
        <list-items-form
          :fetch-loader="loading"
          @onFetchGroupedItems="onFetchGroupedItems"
        />
      </b-card-body>
    </b-card>
    <b-card
      v-if="isSubmitted"
      no-body
    >
      <b-card-header>
        <h4 class="my-0">
          Item List
        </h4>
      </b-card-header>
      <b-card-body>
        <div>
          <ListingTable
            :loading="loading"
            :items="groupItems"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, VBTooltip, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ListingTable from './ListingTable.vue'
import ListItemsForm from './ListItemsForm.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    ListingTable,
    ListItemsForm,
    BAlert,
  },
  data() {
    return {
      loading: false,
      groupItems: [],
      isSubmitted: false,
      errorMessage: false,
    }
  },
  methods: {
    getAmount(record) {
      const { qty, cost } = record
      return (Number(qty) * Number(cost)).toFixed(2)
    },
    getTotalAmount(item) {
      let total = 0
      item.records.forEach(record => {
        total += Number(this.getAmount(record))
      })
      return (total).toFixed(2)
    },
    getParsedData(response) {
      const parsedData = response.map(item => ({
        ...item,
        records: item.records.map(record => ({
          ...record,
          amount: this.getAmount(record),
        })),
        total_items: item.records.length,
        total_amount: this.getTotalAmount(item),
      }))

      return parsedData
    },
    async onFetchGroupedItems(payload) {
      try {
        this.groupItems = []
        this.isSubmitted = false
        this.loading = true
        this.errorMessage = ''

        const { data } = await axios.post('/process_manual_order_data', payload)
        this.groupItems = this.getParsedData(data.data)
        this.isSubmitted = true
      } catch (error) {
        this.errorMessage = error?.response?.data?.error || 'Something went wrong!'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
