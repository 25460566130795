<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center mb-3"
    >
      <b-spinner label="Loading..." />
    </div>
    <b-table
      v-else
      :items="parsedItems"
      :fields="fields"
      responsive
      small
      bordered
      class="item-list-table"
      @row-clicked="onRowClick"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="`width:${ getWidth(field.key)}`"
        >
      </template>
      <template #cell(total_amount)="data">
        {{ formateNumber(data.item.total_amount) }}
      </template>
      <template #row-details="row">
        <div class="p-2">
          <b-table
            :items="row.item.records"
            :fields="orderItem"
            medium
            class="company-group-item-table"
          >
            <template #cell(cost)="data">
              {{ formateNumber(data.item.cost) }}
            </template>
            <template #cell(amount)="data">
              {{ formateNumber(data.item.amount) }}
            </template>
          </b-table>
        </div>
      </template>
      <template #cell(action)="row">
        <div class="d-flex align-items-center">
          <b-button
            :disabled="row.item.isDisabled"
            variant="link"
            class="table-icon-btn btn-icon p-0"
            @click.prevent="onSendOrder(row.item)"
          >
            <feather-icon
              v-b-tooltip.hover="{boundary:'window'}"
              title="Send Order"
              icon="SendIcon"
              size="20"
              class="cursor-pointer mr-1 send-order-icon"
            />
          </b-button>
          <b-button
            class="show-hide-table-data table-icon-btn btn-icon p-0"
            variant="link"
            @click="row.toggleDetails"
          >
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="20"
              class="cursor-pointer mr-1 "
            />
          </b-button>
        </div>
      </template>
    </b-table>
    <send-order
      v-if="sendOrder"
      :order="sendOrder"
      @modal-closed="sendOrder = null"
      @order-sent="onOrderSent"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTable, VBTooltip, BButton,
} from 'bootstrap-vue'
import SendOrder from '@/components/CreateOrder/SendOrder.vue'
import formatMixin from '@/mixins/formatMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    SendOrder,
    BButton,
  },
  mixins: [formatMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sendOrder: null,
      fields: [
        { key: 'company_group_name', label: 'Group name' },
        'total_items',
        { key: 'total_amount', class: 'text-right' },
        'action',
      ],
      succeedOrders: [],
      orderItem: ['code', 'item', { key: 'qty', label: 'Quantity' }, { key: 'cost', class: 'text-right' }, { key: 'amount', class: 'text-right' }, 'company'],
    }
  },
  computed: {
    parsedItems() {
      const parsedData = this.$props.items.map(item => ({
        ...item,
        ...(this.succeedOrders.includes(item.company_group_id) ? { _rowVariant: 'success', isDisabled: true } : {}),
        ...(item.company_group_name === '-' ? { isDisabled: true } : {}),
      }))
      return parsedData
    },
  },
  methods: {
    getWidth(key) {
      switch (key) {
        case 'action':
          return '120px'
        case 'show_details':
          return '200px'
        default:
          return 'auto'
      }
    },
    onOrderSent({ companyGroupId }) {
      this.succeedOrders.push(companyGroupId)
    },
    onSendOrder(data) {
      this.sendOrder = {
        ...data,
        records: data.records.map(item => ({ ...item, selected: true, reorder_items_count: item.qty })),
      }
    },
    onRowClick(item, index, event) {
      const isSendIconClicked = event.target.closest('.send-order-icon')
      if (isSendIconClicked) {
        return
      }
      const trEle = event.target.closest('tr')
      if (trEle) {
        const btnEle = trEle.querySelector('.show-hide-table-data')
        if (btnEle) {
          btnEle.click()
        }
      }
    },
  },

}
</script>

<style lang="scss">

.table-icon-btn.btn-link {
    color: #6e6b7b !important
  }
.company-group-item-table {
  tr, td, th {
    cursor: default !important;
  }
}

.item-list-table {
  tbody tr {
    cursor: pointer !important;
  }

  .b-table-details.table-success, .b-table-details.table-success td, .b-table-details.table-success th{
    background: none !important;
    border-color: #e3e3e3 !important
  }

  }
  .item-list-table {
    .table-success > td  {
      background-color: #b8cba769 !important;
       border-color:  #b8cba769 !important;
    }
  }
</style>
