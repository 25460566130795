<template>
  <ManualOrderComponent />
</template>

<script>
import ManualOrderComponent from '@/components/ManualOrder/index.vue'

export default {
  components: {
    ManualOrderComponent,
  },
}
</script>
