<template>
  <b-modal
    v-model="showModal"
    :title="`Send Order - ${order.company_group_name}`"
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-card-text>
      <b-form @submit.prevent="submitHandler">
        <b-row>
          <b-col md="12">
            <b-alert
              :show="isHashItemNotSelected"
              variant="danger"
            >
              <div class="alert-body">
                <feather-icon
                  icon="AlertTriangleIcon"
                />
                <span> Hash Items for this order are not selected.</span>
              </div>
            </b-alert>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Supplier"
              label-for="supplier"
            >
              <custom-v-select
                v-model="supplier"
                label="name"
                :options="order.suppliers"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              v-model="manualOrder"
            >
              This order will be processed manually <br> (Do not send Email to supplier)
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || !supplier"
        @click="ok()"
      >
        Send
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BButton, BSpinner, BForm, BCol, BRow, BFormGroup, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
    CustomVSelect,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormCheckbox,
    BAlert,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      supplier: null,
      showModal: true,
      saving: false,
      manualOrder: false,
    }
  },
  computed: {
    isHashItemNotSelected() {
      const unselectedHashItems = this.order.records.filter(record => record.is_hash_item === true && record.selected === false).length
      if (unselectedHashItems > 0) {
        return true
      }
      return false
    },
  },
  methods: {
    submitHandler(event) {
      event.preventDefault()
      this.saving = true

      let items = this.order.records.filter(item => item.selected === true)
      items = items.map(item => {
        const newItem = {
          code: item.code,
          company: item.company,
          item: item.item,
          cost: item.cost,
          qty: item.reorder_items_count,
        }
        return newItem
      })

      const data = {
        company_group_id: this.order.company_group_id,
        supplier_id: this.supplier,
        records: items,
        send_email: !this.manualOrder,
      }
      axios.post('/place_order', data)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('order-sent', { companyGroupId: this.order.company_group_id })
          this.saving = false
          this.showModal = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.saving = false
        })
    },
  },
}
</script>
