<template>
  <b-form @submit="onFormSubmit">
    <validation-observer ref="manualOrderItems">
      <b-row
        v-for="(item, index) in orderItems"
        :key="'order-item-' + index"
      >
        <b-col lg="3">
          <validation-provider
            #default="{errors}"
            :vid="'item_name_' + index"
            rules="required"
            name="Item name"
          >
            <b-form-group label="Item Name">
              <custom-v-select
                v-model="item.item_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="itemOptions"
                label="text"
                placeholder="Select Item Name"
                :reduce="(option) => option.id"
                :disabled="loading"
                :class="errors.length > 0 ? 'is-invalid' : ''"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

        </b-col>
        <b-col lg="2">
          <validation-provider
            #default="{ errors }"
            :vid="'qty_' + index"
            rules="required"
            name="qty"
          >
            <b-form-group label="Quantity">
              <b-form-input
                v-model="item.qty"
                type="number"
                placeholder="Enter Quantity"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="my-auto">
          <b-button
            icon
            class="p-0 mt-1"
            variant="link"
            :disabled="isLoading"
            @click="onDeleteItem(index)"
          >
            <feather-icon
              color="red"
              size="15"
              icon="Trash2Icon"
            />
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <div>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="onAddItem"
      >
        + Add Item
      </b-button>
    </div>
    <div
      v-if="orderItems.length"
      class="d-flex justify-content-end"
    >
      <b-button
        variant="primary"
        type="submit"
        :disabled="isLoading"
      >
        Fetch Details
        <b-spinner
          v-if="isLoading"
          small
          label="Small Spinner"
        />
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BForm,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    CustomVSelect,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BSpinner,
  },
  props: {
    fetchLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderItems: [{ item_name: null, qty: null }],
      itemOptions: [],
      loading: false,
    }
  },
  computed: {
    isLoading() {
      return this.loading || this.$props.fetchLoader
    },
  },
  mounted() {
    this.fetchOptions()
  },
  methods: {
    onAddItem() {
      this.orderItems.push({ item_name: null, qty: null })
    },
    onDeleteItem(index) {
      this.orderItems.splice(index, 1)
    },
    async fetchOptions() {
      this.loading = true
      try {
        const { data } = await axios.get('/item_options')
        this.itemOptions = data.data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.error || 'Something went wrong!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },
    async onFormSubmit(event) {
      event.preventDefault()
      const isFormValid = await this.$refs.manualOrderItems.validate()

      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.$emit('onFetchGroupedItems', this.orderItems)
    },
  },
}
</script>
